import React from "react";
import { Navigation, Scrollbar, A11y } from 'swiper';
import styled from "styled-components";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import { Text, Box } from "./styles/Ui";
import { Button } from "./styles/Buttons";
import Icon from "./Icon";
import { Flex } from "./styles/Sections";

import 'swiper/scss';
import 'swiper/scss/navigation';

const SliderComponent = ({ items }) => {

  if(items.length < 3) {
    return (
      <Flex>
        {items.map((item) => {
          const slide = item.casino ? item.casino : item;

          let affiliateLink = slide.affiliateLink && slide.affiliateLink.affiliateLink ? slide.affiliateLink.affiliateLink.uri : undefined;

          const featuredImage = {
            data: slide.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
            alt: slide.featuredImage?.node?.altText || ``,
          }

          let convertedRating = slide.casinoInformation && parseInt(slide.casinoInformation.rating);
          
          return (
            <Box>
              {featuredImage?.data && (
                <GatsbyImage
                  image={featuredImage.data}
                  alt={featuredImage.alt}
                  style={{ 
                    height: 135,
                    width: "100%",
                    borderRadius: 4
                  }}
                />
              )}
              <Text className="center semibold x-large padding-medium">
                {slide.casinoInformation &&
                  <div className="rating">
                    {new Array(convertedRating).fill(null).map(() => (
                      <Icon icon="star-full" size={14} />            
                    ))}
                    {new Array(slide.casinoInformation.rating).fill(null).map(() => (
                      slide.casinoInformation.rating.indexOf('.') !== -1 && (
                        <Icon icon="star-half" size={14}  />            
                      )
                    ))} 
                  </div>
                }
                <p>{slide.title}</p>
                {item.casino ?
                <Button className="green center small"><Link to={affiliateLink}>Till casinot</Link></Button>
                : <Button className="lightBlue center small"><Link to={slide.uri}>Läs recension</Link></Button>}    
              </Text>
            </Box>
          )
        })}
      </Flex>
    )
  } else {
    return (
      <SwiperWrapper>
        <Swiper
          modules={[Navigation, Scrollbar, A11y]}
          spaceBetween={30}
          speed={200}
          slidesPerView={3}
          scrollbar={{ draggable: true }}
          slidesToScroll={1}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          breakpoints={{
            390: {
              slidesPerView: 1.4,
            },
            600: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 4
            },
          }}
        >
          {items.map((item) => {

            const slide = item.casino ? item.casino : item;

            let affiliateLink = slide.affiliateLink && slide.affiliateLink.affiliateLink ? slide.affiliateLink.affiliateLink.uri : undefined;

            const featuredImage = {
              data: slide.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
              alt: slide.featuredImage?.node?.altText || ``,
            }

            let convertedRating = slide.casinoInformation && parseInt(slide.casinoInformation.rating);

            return (
              <SwiperSlide>
                <Box className="is-in-slide">
                  {featuredImage?.data && (
                    <GatsbyImage
                      image={featuredImage.data}
                      alt={featuredImage.alt}
                      style={{ 
                        height: 135,
                        width: "100%",
                        borderRadius: 4
                      }}
                    />
                  )}
                  <Text className="center semibold x-large padding-medium">
                    {slide.casinoInformation &&
                      <div className="rating">
                        {new Array(convertedRating).fill(null).map(() => (
                          <Icon icon="star-full" size={14} />            
                        ))}
                        {new Array(slide.casinoInformation.rating).fill(null).map(() => (
                          slide.casinoInformation.rating.indexOf('.') !== -1 && (
                            <Icon icon="star-half" size={14}  />            
                          )
                        ))} 
                      </div>
                    }
                    <p>{slide.title}</p>
                    {item.casino ?
                    <Button className="green center small"><Link to={affiliateLink}>Till casinot</Link></Button>
                    : <Button className="lightBlue center small"><Link to={slide.uri}>Läs recension</Link></Button>}    
                    </Text>
                </Box>
              </SwiperSlide>
            )
          })}
        </Swiper>
        <div class="swiper-button-next swiper-custom-nav" />
        <div class="swiper-button-prev swiper-custom-nav" />
      </SwiperWrapper>
    )
    }
}

export default SliderComponent;

export const SwiperWrapper = styled.div`
  transform: translate(0, 0);
  display: flex;
  align-items: stretch;
  height: auto;

  .heightAuto {
    @media (min-width: 600px) {
      height: 100%;
    }
  }

  .rating {
    color: #fc0;
  }

  .swiper {
    max-width: 1100px;
  }
  
  .swiper-custom-nav {
    position: absolute;
    background: #f5f5f5;
    border-radius: 100%;
    padding: 20px;
    width: 25px;
    height: 25px;
    
    &:after {
      color: #000;
      font-size: 20px;
      font-weight: 600;
    }
  }

  .swiper-button-prev, .swiper-button-next {
    top: calc(50% + 10px);

    @media (max-width: 600px) {
      display: none;
    }
  }

  .swiper-button-disabled {
    display: none;
  }

  .swiper-button-prev {
    left: -18px;
  }
  .swiper-button-next {
    right: -18px;
  }
`;